import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Logo from '../../img/Logo.png';
import LogoPutih from '../../img/LogoPutih.png';
import { Link, NavLink } from 'react-router-dom';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Navbars = () => {
  const { height, width } = useWindowDimensions();

  const LogoNav = () => {
    if (width >= 600) {
      return <img src={Logo} width="124" />;
    } else {
      return <img src={LogoPutih} width="124" />;
    }
  };
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" variant="dark">
      <Container fluid>
        <Navbar.Brand className="ps-5 pt-4 navbar-brand" href="#home">
          <Link to="/">
            <LogoNav />
          </Link>
          <p className="mt-2 d-none d-md-block">Virtual Reality Tour 360º</p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {/* <Nav.Link
              className={({ isActive, isPending }) => {
                return isActive ? 'active' : isPending ? 'pending' : '';
              }}
              href="/"
            >
              HOME
            </Nav.Link> */}
            <Link
              className={({ isActive, isPending }) => {
                return isActive ? 'active' : isPending ? 'pending' : '';
              }}
              to="/"
            >
              Home
            </Link>
            <Link
              className={({ isActive, isPending }) => {
                return isActive ? 'active' : isPending ? 'pending' : '';
              }}
              to="/About"
            >
              About
            </Link>
            <Link
              className={({ isActive, isPending }) => {
                return isActive ? 'active' : isPending ? 'pending' : '';
              }}
              to="/Category"
            >
              Category
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbars;
