import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Card } from 'react-bootstrap';
import Navbars from '../Navbars';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import fotoGongseng from '../../img/Gongseng.jpg';
import fotoGondang from '../../img/Gondang.jpg';
import fotoBendo from '../../img/Bendo.jpg';
import fotoPidekso from '../../img/Pidekso.jpg';
import fotoTukul from '../../img/Tukul.jpg';
import fotoGongsengM from '../../img/gongseng-mobile.png';
import fotoBendoM from '../../img/bendo-mobile.png';
import fotoGondangM from '../../img/gondang-mobile.png';
import fotoPideksoM from '../../img/pidekso-mobile.png';
import fotoTukulM from '../../img/tukul-mobile.png';
import $ from 'jquery';

const CategoryDetail = (isNav) => {
  const { category } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const bendungans = [
    {
      id: 1,
      title: 'Bendungan Gongseng',
      desc: 'Bendungan Gongseng merupakan salah satu bendungan yang terletak di Bojonegoro, Jawa Timur. Bendungan ini memiliki luasan sekitar 3433 Ha',
      link: 'https://panorago.com/viewer/index.php?code=a4a042cf4fd6bfb47701cbc8a1653ada',
      foto: fotoGongseng,
      fotoM: fotoGongsengM,
      isActive: true,
    },
    {
      id: 2,
      title: 'Bendungan Bendo',
      desc: 'Bendungan Bendo merupakan salah satu Bendungan yang diusulkan dalam Rencana Induk Proyek Pengembangan Wilayah Sungai Bengawan Solo, pada tahun 1974. Bendungan Bendo terletak di Dukuh Bendo, Desa Ngideng, Kecamatan Sawoo, Kabupaten Ponorogo, Propinsi Jawa Timur atau ± 14 km sebelah Tenggara Kota Ponorogo.',
      link: 'https://panorago.com/viewer/index.php?code=1ff8a7b5dc7a7d1f0ed65aaa29c04b1e',
      foto: fotoBendo,
      fotoM: fotoBendoM,
      isActive: true,
    },
    {
      id: 3,
      title: 'Bendungan Gondang',
      desc: 'Bendungan Bendo merupakan salah satu Bendungan yang diusulkan dalam Rencana Induk Proyek Pengembangan Wilayah Sungai Bengawan Solo, pada tahun 1974. Lokasi proyek pembangunan Bendungan Bendo terletak di aliran sungai Keyang, Dukuh Bendo, Desa Ngideng, Kecamatan Sawoo, Kabupaten Ponorogo, Propinsi Jawa Timur atau ± 14 km sebelah Tenggara Kota Ponorogo.',
      link: 'https://panorago.com/viewer/index.php?code=f7e6c85504ce6e82442c770f7c8606f0',
      foto: fotoGondang,
      fotoM: fotoGondangM,
      isActive: true,
    },
    {
      id: 4,
      title: 'Bendungan Tukul',
      desc: 'Coming Soon',
      link: '#',
      foto: fotoTukul,
      fotoM: fotoTukulM,
      isActive: false,
    },
    {
      id: 5,
      title: 'Bendungan Pidekso',
      desc: 'Bendungan Pidekso meruapakan salah satu Bendungan yang terletak di Desa Pidekso & Tukulrejo Kecamatan Giriwoyo dan Desa Sendangsari kecamatan Batuwarno, Kabupaten Wonogiri, Jawa Tengah. Memiliki manfaat sebagai pengendali banjir dan terdapatnya air tampungan yang dapat digunakan untuk memenuhi berbagai keperluan dimusin kemarau, serta menyediakan air irigasi untuk lahan seluas 1.500 Ha',
      link: 'https://panorago.com/viewer/index.php?code=82161242827b703e6acf9c726942a1e4',
      foto: fotoPidekso,
      fotoM: fotoPideksoM,
      isActive: true,
    },
  ];

  useEffect(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsLoaded(false);
    }, 3000);
  }, []);
  return (
    <>
      {isNav ? <Navbars /> : ''}
      <Container className="categoryDetail">
        <h5 className="mb-5 text-capitalize">{category}</h5>
        <div className="row">
          {bendungans.map((bendungan) => (
            <>
              {isLoaded ? (
                <>
                  <div className="col-6 p-1 p-md-2 col-md-3 mb-3">
                    <Card className="is-loading">
                      <div className="image"></div>
                      <Card.Body>
                        <Card.Title className="mb-3"></Card.Title>
                        <Card.Text className="mb-4 text-wrap"></Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              ) : (
                <div className="col-6 p-1 p-md-2 col-md-3 mb-3 animate__animated animate__fadeIn">
                  <Card>
                    <Card.Img variant="top" src={bendungan.fotoM} />
                    <Card.Body>
                      <Card.Title className="mb-3">{bendungan.title}</Card.Title>
                      <Card.Text className="mb-4 text-wrap">{bendungan.desc}</Card.Text>

                      {bendungan.isActive ? (
                        // <Button href={bendungan.link} target="__BLANK" variant="primary btn-tour" className="btn-tour animate__animated animate__fadeInUp">
                        //   Mulai 360 Tour
                        // </Button>
                        <Link className="card-link" to={'/panorama/' + bendungan.id}>
                          <Button className="btn-tour" variant="btn">
                            Mulai Tour
                          </Button>
                        </Link>
                      ) : (
                        <Link className="card-link" to="#">
                          <Button className="btn-soon" variant="btn">
                            Cooming Soon
                          </Button>
                        </Link>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              )}
            </>
          ))}
        </div>
      </Container>
    </>
  );
};

export default CategoryDetail;
