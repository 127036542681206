import React, { useState, useEffect, useRef } from 'react';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, useParams } from 'react-router-dom';

const Panorama = () => {
  const { id } = useParams();
  const [link, setLink] = useState(null);

  // switch ({ id }) {
  //   case '1':
  //     link = 'asd';
  //     break;

  //   default:
  //     break;
  // }
  useEffect(() => {
    // if (id === '1') {
    //   setLink('https://panorago.com/viewer/index.php?code=a4a042cf4fd6bfb47701cbc8a1653ada');
    // }
    switch (id) {
      case '1':
        setLink('https://panorago.com/viewer/index.php?code=a4a042cf4fd6bfb47701cbc8a1653ada');
        break;

      case '2':
        setLink('https://panorago.com/viewer/index.php?code=1ff8a7b5dc7a7d1f0ed65aaa29c04b1e');
        break;

      case '3':
        setLink('https://panorago.com/viewer/index.php?code=f7e6c85504ce6e82442c770f7c8606f0');
        break;

      case '4':
        setLink('https://panorago.com/viewer/index.php?code=96da2f590cd7246bbde0051047b0d6f7');
        break;

      case '5':
        setLink('https://panorago.com/viewer/index.php?code=82161242827b703e6acf9c726942a1e4');
        break;

      default:
        break;
    }
  }, []);

  return (
    <>
      <Container fluid className="panorama">
        <Button className="btn-home text-center d-grid justify-content-center" href="/">
          <Link
            className={({ isActive, isPending }) => {
              return isActive ? 'btn-home active' : '';
            }}
            to="/"
          >
            <FontAwesomeIcon icon={solid('home')} className="icon" />
            <span>Kembali ke Beranda</span>
          </Link>
        </Button>
        <iframe
          title="BBWSBS Virtual Tour 360"
          id="svt_iframe_82161242827b703e6acf9c726942a1e4"
          allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;"
          width="100%"
          height="100%"
          frameBorder=""
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={link}
        ></iframe>
      </Container>
    </>
  );
};

export default Panorama;
