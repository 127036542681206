import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import fotoGongseng from '../../img/Gongseng.jpg';
import fotoGondang from '../../img/Gondang.jpg';
import fotoBendo from '../../img/Bendo.jpg';
import fotoPidekso from '../../img/Pidekso.jpg';
import fotoTukul from '../../img/Tukul.jpg';
import fotoGongsengM from '../../img/gongseng-mobile.png';
import fotoBendoM from '../../img/bendo-mobile.png';
import fotoGondangM from '../../img/gondang-mobile.png';
import fotoPideksoM from '../../img/pidekso-mobile.png';
import fotoTukulM from '../../img/tukul-mobile.png';
import Ratio from 'react-bootstrap/Ratio';

import $ from 'jquery';
import loadingFoto from '../../img/ball.gif';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import '../../App.css';
import Modal from 'react-bootstrap/Modal';
import 'animate.css';
import { Link, NavLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Spinner from 'react-bootstrap/Spinner';

import Navbars from '../Navbars';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Home(isNav) {
  const nextIcon = <FontAwesomeIcon icon={solid('angle-right')} color="#00988f" size="3x" />;
  const prevIcon = <FontAwesomeIcon icon={solid('angle-left')} color="#00988f" size="3x" />;
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [cookies, setCookie] = useCookies(['user_login']);
  const ref = useRef(null);

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setCookie('user_login', true, { path: '/' });
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const { height, width } = useWindowDimensions();

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };

  $(document).keydown(function (e) {
    if (e.which === 37) {
      ref.current.prev();
      return false;
    }
  });

  useEffect(() => {
    if (cookies.user_login) {
      setShow(false);
    } else {
      setShow(true);
    }
    setIsLoaded(true);
  }, []);

  const bendungans = [
    {
      id: 1,
      title: 'Bendungan Gongseng',
      desc: 'Bendungan Gongseng merupakan salah satu bendungan yang terletak di Bojonegoro, Jawa Timur. Bendungan ini memiliki luasan sekitar 3433 Ha',
      link: 'https://panorago.com/viewer/index.php?code=a4a042cf4fd6bfb47701cbc8a1653ada',
      foto: fotoGongseng,
      fotoM: fotoGongsengM,
      isActive: true,
    },
    {
      id: 2,
      title: 'Bendungan Bendo',
      desc: 'Bendungan Bendo merupakan salah satu Bendungan yang diusulkan dalam Rencana Induk Proyek Pengembangan Wilayah Sungai Bengawan Solo, pada tahun 1974. Bendungan Bendo terletak di Dukuh Bendo, Desa Ngideng, Kecamatan Sawoo, Kabupaten Ponorogo, Propinsi Jawa Timur atau ± 14 km sebelah Tenggara Kota Ponorogo.',
      link: 'https://panorago.com/viewer/index.php?code=1ff8a7b5dc7a7d1f0ed65aaa29c04b1e',
      foto: fotoBendo,
      fotoM: fotoBendoM,
      isActive: true,
    },
    {
      id: 3,
      title: 'Bendungan Gondang',
      desc: 'Bendungan Bendo merupakan salah satu Bendungan yang diusulkan dalam Rencana Induk Proyek Pengembangan Wilayah Sungai Bengawan Solo, pada tahun 1974. Lokasi proyek pembangunan Bendungan Bendo terletak di aliran sungai Keyang, Dukuh Bendo, Desa Ngideng, Kecamatan Sawoo, Kabupaten Ponorogo, Propinsi Jawa Timur atau ± 14 km sebelah Tenggara Kota Ponorogo.',
      link: 'https://panorago.com/viewer/index.php?code=f7e6c85504ce6e82442c770f7c8606f0',
      foto: fotoGondang,
      fotoM: fotoGondangM,
      isActive: true,
    },
    {
      id: 4,
      title: 'Bendungan Tukul',
      desc: 'Bendungan Tukul berada di Sungai Telu, Desa Karanggede,  Kecamatan Arjosari, Kabupaten Pacitan, Provinsi Jawa Timur. Pembangunan Bendungan Tukul sangat diperlukan dalam rangka pengembangan infrastruktur irigasi untuk mendukung ketahanan pangan nasional dan penyediaan air baku Kabupaten Pacitan sesuai RTRW Kabupaten Pacitan 2009-2028.',
      link: 'https://panorago.com/viewer/index.php?code=96da2f590cd7246bbde0051047b0d6f7',
      foto: fotoTukul,
      fotoM: fotoTukulM,
      isActive: true,
    },
    {
      id: 5,
      title: 'Bendungan Pidekso',
      desc: 'Bendungan Pidekso meruapakan salah satu Bendungan yang terletak di Desa Pidekso & Tukulrejo Kecamatan Giriwoyo dan Desa Sendangsari kecamatan Batuwarno, Kabupaten Wonogiri, Jawa Tengah. Memiliki manfaat sebagai pengendali banjir dan terdapatnya air tampungan yang dapat digunakan untuk memenuhi berbagai keperluan dimusin kemarau, serta menyediakan air irigasi untuk lahan seluas 1.500 Ha',
      link: 'https://panorago.com/viewer/index.php?code=82161242827b703e6acf9c726942a1e4',
      foto: fotoPidekso,
      fotoM: fotoPideksoM,
      isActive: true,
    },
  ];

  useEffect(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsLoaded(false);
    }, 1000);
  }, []);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <>
        {isLoaded ? (
          <Container fluid className="text-center loading align-items-center animate__animated animate__fadeOut animate__slow justify-content-center d-flex">
            <img src={loadingFoto} width="10%" height="10%" />
          </Container>
        ) : (
          <>
            {isNav ? <Navbars /> : ''}
            <Carousel id="home" nextIcon={nextIcon} touch prevIcon={prevIcon} activeIndex={index} onSelect={handleSelect} ref={ref} indicators={false}>
              {bendungans.map((bendungan) => (
                <Carousel.Item key={bendungan.id}>
                  <img
                    className={'d-block w-100 foto-bendungan animate__animated ' + (width <= 500 ? 'animate__bounceInDown' : 'animate__fadeInDown')}
                    src={width <= 500 ? bendungan.fotoM : bendungan.foto}
                    alt={width <= 500 ? bendungan.fotoM : bendungan.foto}
                  />
                  <Carousel.Caption>
                    <div className="d-flex mb-4 mb-md-3 animate__animated animate__fadeInDown">
                      <Button variant="primary btn-next me-2" onClick={onPrevClick}>
                        <FontAwesomeIcon icon={solid('angle-left')} />
                      </Button>
                      <Button variant="primary btn-next me-4" onClick={onNextClick}>
                        <FontAwesomeIcon icon={solid('angle-right')} />
                      </Button>
                    </div>
                    <h3 className="text-dark animate__animated animate__slideInLeft">{bendungan.title}</h3>
                    <p className="text-dark animate__animated animate__slideInRight">{bendungan.desc}</p>
                    {bendungan.isActive ? (
                      <Link className="btn-tour animate__animated animate__fadeInUp" to={'/panorama/' + bendungan.id}>
                        Mulai 360 Tour
                      </Link>
                    ) : (
                      <Link className="animate__animated animate__fadeInUp btn-disabled" to={'#'}>
                        Cooming Soon
                      </Link>
                    )}
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
            <Modal show={show} onHide={handleClose} backdrop="static" dialogClassName="modal-90w" centered>
              <Modal.Header closeButton className="text-center">
                <Modal.Title className="w-100">
                  <p className="text-center">Video Opening 360</p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="py-5">
                <Ratio aspectRatio={45}>
                  <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/E2SsI2QXy7g?autoplay=1&mute=1" title="BBWSBS Virtual Tour 360" frameBorder="0" allow="autoplay" allowFullScreen></iframe>
                </Ratio>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default Home;
