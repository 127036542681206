import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import bendungFoto from '../../img/dam.png';
import embungFoto from '../../img/lake.png';
import Navbars from '../Navbars';
import loadingFoto from '../../img/ball.gif';

const Category = (isNav) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsLoaded(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoaded ? (
        <Container fluid className="text-center loading align-items-center animate__animated animate__fadeOut animate__slow justify-content-center d-flex">
          <img src={loadingFoto} width="10%" height="10%" />
        </Container>
      ) : (
        <>
          {isNav ? <Navbars /> : ''}
          <Container className="category">
            <h5 className="mb-5 animate__animated animate__fadeInUp">Category</h5>
            <div className="row">
              <div className="col-6 col-md-3 mb-4">
                <Link className="card-link" to="/Category/bendungan">
                  <Card className="animate__animated animate__fadeInUp">
                    <Card.Img variant="top" src={bendungFoto} />
                    <Card.Body>
                      <Card.Title>Bendungan</Card.Title>
                      <Card.Text>Some quick example text to build on the Bendungan and make up the bulk of the card's content.</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default Category;
