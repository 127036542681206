import React, { useState, useEffect } from 'react';
import About from './pages/About';
import Home from './pages/Home';
import Panorama from './pages/Panorama';
import Category from './pages/Category';
import CategoryDetail from './pages/Category-detail';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home nav={true} />} />
          <Route path="/Home" element={<Home nav={true} />} />
          <Route path="/About" element={<About nav={true} />} />
          <Route path="/Category" element={<Category nav={true} />} />
          <Route path="/Category/:category" element={<CategoryDetail nav={true} />} />
          <Route path="/Panorama/:id" element={<Panorama />} />
        </Routes>
      </div>
    </Router>
  );
}
