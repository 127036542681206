import React, { useState, useEffect } from 'react';
import aboutImg from '../../img/about.png';
import Navbars from '../Navbars';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import loadingFoto from '../../img/ball.gif';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import fotoIbnu from '../../img/Hamdam.jpg';
import fotoRizal from '../../img/Rizal.jpg';
import fotoTatak from '../../img/Tatak.jpg';
import fotoIngga from '../../img/Ingga.jpg';
import fotoEddy from '../../img/Edi.jpg';
import fotoLaksita from '../../img/Sita.jpg';
import fotoAgus from '../../img/Agus.jpg';
import fotoDaffa from '../../img/Daffa.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

const About = (isNav) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsLoaded(false);
    }, 1000);
  }, []);

  const slideWidth = 30;

  const sisdas = [
    {
      name: 'Rizal Kusuma',
      foto: fotoRizal,
      ig: 'https://www.instagram.com/ijal_jali/',
    },
    {
      name: 'Agus Sabar',
      foto: fotoAgus,
      ig: 'https://www.instagram.com/asbar88/',
    },
    {
      name: 'Edi Karuniawan',
      foto: fotoEddy,
      ig: 'https://www.instagram.com/eddykarun/',
    },
    {
      name: 'Ingga Wahyu',
      foto: fotoIngga,
      ig: 'https://www.instagram.com/inggawahyusaputra/',
    },
    {
      name: 'Laksita Gama',
      foto: fotoLaksita,
      ig: '#',
    },
    {
      name: 'Tatak Puji',
      foto: fotoTatak,
      ig: 'https://www.instagram.com/tatak.puji/',
    },
    {
      name: 'Ibnu Hamdam',
      foto: fotoIbnu,
      ig: 'https://www.instagram.com/syai06/',
    },
    {
      name: 'Muhammad Daffa',
      foto: fotoDaffa,
      ig: 'https://www.instagram.com/muhammad.daffaa/',
    },
  ];

  const [index, setIndex] = useState(0);

  const slideLeft = () => {
    setIndex(index - 1);
    console.log(index);
  };

  const slideRight = () => {
    setIndex(index + 1);
    console.log(index);
  };
  return (
    <>
      {isLoaded ? (
        <Container fluid className="text-center loading align-items-center animate__animated animate__fadeOut animate__slow justify-content-center d-flex">
          <img src={loadingFoto} width="10%" height="10%" />
        </Container>
      ) : (
        <>
          {isNav ? <Navbars /> : ''}
          <div className="container-fluid about text-center">
            <img src={aboutImg} className="img-fluid img-about animate__animated animate__bounceInDown" />
            <div className="about-desc">
              <h1 className="text-start animate__animated animate__fadeInLeft animate__slow">About</h1>
              <p className="text-start text-desc animate__animated animate__fadeInLeft">
                BBWSBS Virtual Reality Tour 360 merupakan website yang berisi mengenai panorama foto 360 insfrastuktur yang ada di BBWS Bengawan Solo. Anda dapat menjelajahi setiap sudut yang ada di masing-masing insfrastuktur. Website ini
                akan terus dikembangkan dan dikelola oleh{' '}
                <Badge bg="primary" pill className="animate__animated animate__heartBeat animate__slow animate__infinite">
                  SISDA
                </Badge>
              </p>
            </div>
          </div>
          <div className="container-fluid teams row">
            {sisdas.map((sisda, n) => {
              console.log(index);
              let position = n > index ? 'nextCard' : n === index ? 'activeCard' : 'prevCard';
              return (
                <div className="card-about col-4 mt-2 col-md">
                  <a href={sisda.ig} target="__BLANK">
                    <Card className={position + ' '}>
                      <Card.Img src={sisda.foto} alt={sisda.name} className="img-fluid" />
                      <div className="card-text">{sisda.name}</div>
                    </Card>
                  </a>
                </div>
              );
            })}

            {/* <Card className=" me-3">
                  <Card.Img src={fotoIbnu} alt="Card image" className="img-fluid" />
                </Card>
                <Card className=" me-3">
                  <Card.Img src={fotoIbnu} alt="Card image" className="img-fluid" />
                </Card>
                <Card className=" me-3">
                  <Card.Img src={fotoIbnu} alt="Card image" className="img-fluid" />
                </Card>
                <Card className=" me-3">
                  <Card.Img src={fotoIbnu} alt="Card image" className="img-fluid" />
                </Card>
                <Card className=" me-3">
                  <Card.Img src={fotoIbnu} alt="Card image" className="img-fluid" />
                </Card> */}
          </div>
        </>
      )}
    </>
  );
};

export default About;
